$(() => {
    let $nav = $('nav .nav_btn');
    let $body = $('body');
    let $projekte = $('.projekte');
    // let moveInitTime = 10000;
    let $header = $('header');
    let $indexImage = $('.index .image');

    // Get Navi height
    const header = $header.innerHeight();
    document.documentElement.style.setProperty('--header', `${header}px`);

    window.addEventListener('resize', () => {
        const header = $header.innerHeight();
        document.documentElement.style.setProperty('--header', `${header}px`);
    });

    // Set all links to _blank
    $('main a').each(function () {
        $(this).attr('target', '_blank');
    });

    $('.projekte a').each(function () {
        $(this).attr('target', '_blank');
    });

    $('.index a').each(function () {
        $(this).attr('target', '_blank');
    });

    $('.index_titles a').each(function () {
        $(this).attr('target', '_blank');
    });

    $('.footer a').each(function () {
        $(this).attr('target', '_blank');
    });

    // Triggers
    initNavi();

    $body.on('click', 'header', function (event) {
        if (!$(event.target).is('a, a *')) {
            viewStart()
        }
    })

    $body.on('click', 'nav .nav_btn', function (event) {

        // Change view to slideshow
        if ($body.attr('view') === 'init') {
            $body.attr('view', 'slid');
            navShortBlocker();

            // Activate first project
            let $project = $('.project');

            $project.first().addClass('active');
            $projekte.attr('status', 'open');

            let $firstImage = $('.project.active img').first();

            showImage(event, $firstImage);

            // Change view to gallery
        } else if ($body.attr('view') === 'slid') {
            viewGallery();

            // Return to start
        } else if ($body.attr('view') === 'gale') {
            viewStart();
        }
    });

    $body.on('click', 'main', function (event) {
        if ($body.attr('view') === 'slid') {
            nextImage(event)
        }
    });

    $body.on('click', '.projekte img', function (event) {
        if ($body.attr('view') === 'slid') {
            nextImage(event)
        }
    });

    $body.on('click', '.project.active .info_btn', function () {
        if ($(this).parent().attr('status') === 'info') {
            $(this).parent().attr('status', '');
        } else {
            $(this).parent().attr('status', 'info');
        }
    });

    $body.on('click', '.index .image', function () {
        let targetID = $(this).attr('project')
        $indexImage.each(function () {
            $(this).removeClass('active');
        });
        $(this).addClass('active');
        $body.addClass('show_index');

        $('.index_titles .project').each(function () {
            if ($(this).attr('project') === targetID) {
                $(this).addClass('active')
            }
        })
    });

    $body.on('mouseleave', '.index .image', function () {
        let thisID = $(this).attr('project')
        let targetID

        $indexImage.each(function () {
            if ($(this).is(':hover')) {
                targetID = $(this).attr('project')
            }
        })
        if (thisID !== targetID) {
            $indexImage.each(function () {
                $(this).removeClass('active');
            });
            $('.index_titles .project').each(function () {
                $(this).removeClass('active');
            })
            $body.removeClass('show_index');
        }
    });

    $body.on('mousemove', function (event) {
        if ($body.attr('view') === 'slid') {
            let posX = event.clientX
            let posY = event.clientY
            let target = $('.nav_next')
            target.css('left', posX + 'px')
            target.css('top', posY + 'px')


            if (($header.is(':hover')) || ($('.project.active .info_cont').is(':hover')) || ($('.project.active .info_btn').is(':hover')) || ($('.project.active .info_tit').is(':hover')) || ($('nav').is(':hover'))) {
                $body.addClass('proxy')
            } else {
                $body.removeClass('proxy')
            }
        }
    })

    $body.on('click', '.news_container .close', function () {
        $('.news_container').removeClass('open')
    })

    $body.on('click', '.news_container .open', function () {
        $('.news_container').addClass('open')
    })

    // Functions
    function initNavi() {
        // let min = 20;
        // let max = 80;
        // let rand_pos_01 = Math.floor(Math.random() * (max - min + 1) + min);
        // let rand_pos_02 = Math.floor(Math.random() * (max - min + 1) + min);

        // $nav.css('bottom', rand_pos_01 + '%');
        // $nav.css('right', rand_pos_02 + '%');
        $nav.css('opacity', '0');

        setTimeout(function () {
            // let min = 20;
            // let max = 80;
            // let rand_pos_01 = Math.floor(Math.random() * (max - min + 1) + min);
            // let rand_pos_02 = Math.floor(Math.random() * (max - min + 1) + min);
            // $nav.css('bottom', rand_pos_01 + '%');
            // $nav.css('right', rand_pos_02 + '%');
            $body.attr('view', 'init');
            $nav.animate({opacity: 1}, 1000);
            // setTimeout(function () {
            //     moveInit();
            // }, moveInitTime);
        }, 100);
    }

    // function moveInit() {
    //     if ($body.attr('view') === 'init') {
    //         let min = 20;
    //         let max = 80;
    //         let rand_pos_01 = Math.floor(Math.random() * (max - min + 1) + min);
    //         let rand_pos_02 = Math.floor(Math.random() * (max - min + 1) + min);
    //         $nav.css('bottom', rand_pos_01 + '%');
    //         $nav.css('right', rand_pos_02 + '%');
    //
    //         setTimeout(function () {
    //             moveInit();
    //         }, moveInitTime);
    //     }
    // }

    function viewGallery() {
        $body.attr('view', 'gale');
        $projekte.attr('status', '');
        $('.project.active').attr('status', '');
        $('.project .images img.show').each(function () {
            $(this).removeClass('show');
        });

        $('.index .image img').each(function () {
            if ($(this).outerWidth() > $(this).outerHeight()) {
                $(this).addClass('landscape');
            } else if ($(this).outerWidth() < $(this).outerHeight()) {
                $(this).addClass('portrait');
            } else {
                $(this).addClass('square');
            }
        });

        setTimeout(function () {
            $('.project.active').removeClass('active');
        }, 1000);
    }

    function viewStart() {
        $nav.css('opacity', '0');
        $body.attr('view', 'star');
        resetProjekte()

        setTimeout(function () {
            initNavi();
        }, 1000);

    }

    function nextImage(event) {
        let $active = $('.project.active img.show').last();
        if ($active.next().length === 1) {
            showImage(event, $active.next());
        } else if ($active.parent().parent().next().length === 1) {
            $active.parent().parent().removeClass('active');
            $active.parent().parent().next().addClass('active');
            showImage(event, $active.parent().parent().next().find('img').first());
        } else {
            viewGallery();
        }
    }

    function showImage(event, target) {
        let cursX = event.pageX - $body.offset().left;
        let cursY = event.pageY - $body.offset().top;

        target.first().addClass('show');

        // Print image orientqation
        if (target.outerHeight() > target.outerWidth()) {
            target.addClass('portrait');
        } else if (target.outerHeight() < target.outerWidth()) {
            target.addClass('landscape');
        } else {
            target.addClass('square');
        }

        // Set image position
        if ((cursX * 2) < target.outerWidth()) {
            target.css('left', '0px');
        } else if ((cursX + (target.outerWidth() * 0.5)) > $(window).width()) {
            target.css('right', '0px');
        } else {
            target.css('left', cursX - (target.outerWidth() * 0.5));
        }

        if ((cursY * 2) < target.outerHeight()) {
            target.css('top', '0px');
        } else {
            target.css('top', cursY - (target.outerHeight() * 0.5));
        }


    }

    function navShortBlocker() {
        $nav.css('pointer-events', 'none');

        setTimeout(function () {
            $nav.css('pointer-events', 'unset');
        }, 1000);
    }

    function resetProjekte() {
        $('.projekte .project').each(function () {
            $(this).removeClass('active')
        })
        $('.projekte .project img').each(function () {
            $(this).removeClass('show')
        })
    }


});
